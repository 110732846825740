import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';

const DesktopApp = lazy(() => import('./desktop/src/index'));
const MobileApp = lazy(() => import('./mobile/src/index'));

const App = () => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    const handler = () => setIsMobile(window.matchMedia("(max-width: 768px)").matches);
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return (
    <Suspense fallback={<div>    <div className="flex justify-center items-center h-screen">
    <div className="lds-dual-ring"></div>
  </div></div>}>
      {isMobile ? <MobileApp /> : <DesktopApp />}
    </Suspense>
  );
};

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<App />);
